<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <QRReader v-if="!isFromLinePay" :camera="camera" @query="onQuery"></QRReader>
                <div v-else class="text-xs-center">
                    <img src="@/assets/logo_wash.svg" />
                    <p class="subheading">{{ message }}</p>
                </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-1 pt-2">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <package-list
                            :packages="packages"
                            @confirm="confirmSelection"
                            @back="setStepNo(1)"
                        ></package-list>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <v-expansion-panel v-model="panel" expand>
                            <v-expansion-panel-content class="cyan lighten-5">
                                <template v-slot:header>
                                    <div class="subheading">
                                        {{ $t('takeout.details') }}
                                    </div>
                                </template>
                                <v-list subheader two-line class="cyan lighten-5">
                                    <v-list-tile v-for="p in selectedPackages" :key="p.track_no">
                                        <v-list-tile-content>
                                            <v-list-tile-title>{{ $t('takeout.cell') }}&nbsp;{{ p.door_id }}</v-list-tile-title>
                                            <v-list-tile-sub-title>{{ $t('takeout.cost') }}&nbsp;{{ finalAmount(p) }}&nbsp;{{ $t('takeout.NTD') }}&nbsp;{{ $t('takeout.info') }}&nbsp;<b>{{ typeName(p.note) }}</b></v-list-tile-sub-title>
                                            <v-list-tile-sub-title>{{ $t('takeout.storage-time') }}&nbsp;{{ p.putin.time }}&nbsp;{{ $t('takeout.back-time') }}&nbsp;{{ p.back_time }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                </v-list>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <payment-info
                            :enable="stepNo === 3"
                            :amount="totalAmount"
                            :boxId="boxId"
                            :doorId="doorIds"
                            :period=600
                            :note="$t('takeout.complete-payment')"
                            @refresh="getPackages"
                        ></payment-info>
                        <payment-selector
                            :enable="paySelectEnable"
                            :amount="totalAmount"
                            :doorId="doorIds"
                            :transType="transtype"
                            :action="action"
                            @back="setStepNo(2)"
                            @pay="openDoors"
                            ref="paymentSelector"
                        ></payment-selector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-list two-line subheader>
                            <v-subheader> {{ $t('takeout.get') }} {{ takeoutPackages.length }} {{ $t('takeout.package') }} </v-subheader>
                            <v-list-tile avatar v-for="(p, index) in takeoutPackages" :key="p.track_no">
                                <v-list-tile-content>
                                    <v-list-tile-title class="title">{{ $t('takeout.cell') }} {{ p.door_id }}</v-list-tile-title>
                                    <v-list-tile-sub-title>{{ $t('takeout.info') }}&nbsp;<b>{{ typeName(p.note) }}</b></v-list-tile-sub-title>
                                    <v-list-tile-sub-title v-show="p.message" class="error--text">{{ p.message }}</v-list-tile-sub-title>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <v-btn v-if="p.openable" dark color="cyan" class="subheading" @click="reopen(index)">{{ $t('takeout.reopen') }}</v-btn>
                                    <v-chip v-else label diabled outline color="error" class="subheading">{{ $t('takeout.can-not-open') }}</v-chip>
                                </v-list-tile-action>
                            </v-list-tile>
                        </v-list>
                        <p v-for="p in takeoutPackages" :key="p.track_no">
                            <v-alert :value="true" type="warning" class="subheading" v-if="istakepagePackagealarm(p)">
                                {{ $t('takeout.sure-close') }} <b style='color:black;'>{{ $t('takeout.monthly-cell') }} {{ p.door_id }} </b> {{ $t('takeout.avoid-loss') }}
                            </v-alert>
                        </p>
                        <electronic-money-detail v-if="isPayByElectronicMoney" :box-id="boxId" :detail="payinfo.detail"></electronic-money-detail>
                        <v-btn large block class="subheading" color="primary" @click="leave()">{{ $t('takeout.done') }}</v-btn>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
            ></message-dialog>
            <v-dialog persistent v-model="emptyDialog" width="250">
                <v-card class="text-xs-center">
                    <v-card-text  class="title">
                        {{ dialogMessage }}
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn block class="subheading" color="primary" @click="leave()"> {{ $t('takeout.back') }} </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import TakeoutMixin from '@/mixins/TakeoutMixin'
import WaitingDialog from '@/components/WaitingDialog'
import PaymentInfo from '@/components/payments/PaymentInfo'
import PaymentSelector from '@/components/payments/PaymentSelector'
import PackageList from '@/components/PackageList'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import ElectronicMoneyDetail from '@/components/payments/ElectronicMoneyDetail'
import ElectronicMoneyDetailMixin from '@/mixins/ElectronicMoneyDetailMixin'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { publish } from '@/utils/MQTTClient'
import { TransTypes } from '@/mixins/PutinMixin'
import i18n from '@/i18n'
import { MQTT_ACTION } from '@/utils/MQTTClient'
import { LinePayType } from '@/components/payments/LinePay'
import { JKOPayType } from '@/components/payments/JKOPay'
import { HOMETYPES } from '@/utils/utils'

const INTERVAL_BTN_FEE_AND_CONTROL = 60;

export default {
    name: 'washOutBox',
    components: { WaitingDialog, PaymentSelector, PaymentInfo, ElectronicMoneyDetail, PackageList, MessageDialog },
    mixins: [ BoxIdWatcherMixin, TakeoutMixin, ControlResultWatcher, WaitDialogControl, ElectronicMoneyDetailMixin, MessageDialogControl ],
    data() {
        store.commit('setPageHome', HOMETYPES.WASH)
        return {
            titles: [i18n.t('takeout.pickup-QR-code'), i18n.t('takeout.pick-list'), i18n.t('takeout.deduction'), i18n.t('takeout.pick-list')],
            boxId: '',
            stepNo: 1,
            doorId: 0,
            camera: 'off',
            qrContent: { action: 'auth' },
            qrcodeKey: 0,
            packages: [],
            paySelectEnable: false,
            emptyDialog: false,
            panel: [true],
            billingTime: new Date().getTime(),
            takeoutPackages: [],
            rpiMode: false,
            selectTrackNos: null,
            selectNotes: null,
            transtype: TransTypes.WashClerk,
            isFromLinePay: false,
            zero_stamp: 0,
            action: MQTT_ACTION.TAKEOUT_ACTION,
            dialogMessage: i18n.t('takeout.no-package'),
            message: i18n.t('takeout.line-pay-transaction')
        }
    },
    watch: {
        boxId() {
            if (this.boxId !== '') {
                if (this.isFromLinePay) return;
                if (this.rpiMode == true) {
                    this.setStepNo(4)
                    return
                }
                var self = this
                self.getPackages(function (packages) {
                    if (packages.length === 0) self.emptyDialog = true
                    else if (packages.length === 1 && self.totalAmount === 0) {
                        if (self.zero_stamp === 0)
                            self.zero_stamp = Date.parse(new Date())/1000
                        self.openDoors()
                    } else if (packages.length === 1) {
                        self.packages = packages
                        self.setStepNo(3)
                        self.paySelectEnable = true
                    } else {
                        self.setStepNo(2)
                        self.paySelectEnable = true
                    }
                })
            }
        },
        controlResult() {
            this.closeDialog()
            const resultObj = this.controlResult
            this.updatePackagesWithControlResult(resultObj)
            this.setStepNo(4)
        }
    },
    computed: {
        selectedPackages() {
            return this.packages.filter(p => p.track_no in this.selectTrackNos)
        },
        doorIds() {
            return this.selectedPackages ? this.selectedPackages.map(function(e) { return e.door_id }) : []
        },
        totalAmount() {
            if (this.selectedPackages) return this.selectedPackages.reduce((s, p) => s + this.finalAmount(p), 0)
            else return 0
        }
    },
    methods: {
        initAll() {
            store.commit('initTransaction');
            store.commit('setTitle', this.titles[0]);
            store.commit('setTargetBoxId', '');
            store.commit('setReopenTimeLimit', false);
            store.commit('setMQTTPayload', null);
            store.commit('setPageHome', HOMETYPES.WASH);
            this.boxId = ''
            this.doorId = 0
            this.stepNo = 1
            this.emptyDialog = false
            this.packages = []
            this.paySelectEnable = false
            this.takeoutPackages = []
            this.rpiMode = false
            this.selectTrackNos = null
            this.selectNotes = null
            this.zero_stamp = 0
            this.isFromLinePay = false
            store.commit('setIsFromLinePay', false)
        },
        reloadqrcode(){
            store.commit('setClientId')
        },
        setStepNo: function(no) {
            if (no === 1) {
                this.initAll()
                this.camera = 'auto'
            } else this.camera = 'off'
            this.stepNo = no;
            store.commit('setTitle', this.titles[this.stepNo - 1]);
            this.closeDialog()
        },
        async getPackages(handler = null) {
            this.dialogMessage = i18n.t('takeout.no-package')
            this.showDialog(i18n.t('takeout.get-list'), i18n.t('takeout.fail-get-list'), 15000)
            this.billingTime = new Date().getTime()
            let url = `${HostUrl}/api/box/${this.boxId}/washclient/takeout`
            if (this.doorId != 0) {
                url = `${HostUrl}/api/box/${this.boxId}/${this.doorId}/${this.transtype}/takeout`
            }
            try {
                let response = await axios.get(url, getAxiosConfig())
                if (response.data.code == 0) {
                    this.packages = response.data.packages
                    if (this.selectTrackNos === null) {
                        this.selectTrackNos = {}
                        this.selectNotes = {}
                        this.packages.forEach(p => { this.selectTrackNos[p.track_no] = p.type, this.selectNotes[p.track_no] = p.note })
                    }
                } else throw new Error(response.data.message)
            } catch (error) {
                this.dialogMessage = error.message
            } finally {
                this.closeDialog()
            }
            // 於取得新的 Packages 列表後執行 handler hook
            // 用於首次進來此頁面根據 package 內容判斷要跳到哪一頁的動作
            if (handler) handler(this.packages)
        },
        confirmSelection(selections) {
            this.selectTrackNos = selections
            if (this.totalAmount === 0) {
                if (this.zero_stamp === 0)
                    this.zero_stamp = Date.parse(new Date())/1000
                this.openDoors()
            }
            else this.setStepNo(3)
        },
        finalAmount(p) {
            let type = this.selectTrackNos[p.track_no]
            switch (type) {
                case TransTypes.Delivery:
                case TransTypes.Leave:
                    return p.amount
                case TransTypes.LongLeave:
                    return p.relet_amount || p.amount // 針對 relet_amount 為 null 的狀況, 表示沒有續租方案, 使用 amount 為金額
                case TransTypes.WashClerk:
                    return p.amount
                default:
                    store.commit('setSnackBar', { message: i18n.t('takeout.calculation-wrong'), color: 'error' })
                    return undefined
            }
        },
        openDoors(payment=null) {
            this.payinfo = payment
            let url = ""
            let timestamp = Date.parse(new Date())/1000
            let payload = {
                client_id: store.getters.clientId,
                billing_time: this.billingTime,
                track_no: this.selectTrackNos,
                memo: this.selectNotes,
                payment: payment
            }
            if(payment !== null) {
                if (payment.type === JKOPayType) {
                    let JKOParameter = {
                        payload,
                        box_id: this.boxId,
                        from: 'takeout'
                    }
                    store.commit('setJkoPayParameter', JKOParameter)
                    this.$router.push(`/${i18n.locale}/washhost/jkopay`)
                    this.initAll()
                    return
                } else if (payment.type === LinePayType) {
                    let LinePayParameter = {
                        payload,
                        box_id: this.boxId,
                        from: 'takeout'
                    }
                    store.commit('setLinePayParameter', LinePayParameter)
                    this.$router.push(`/${i18n.locale}/washhost/linepay`)
                    this.initAll()
                    return
                } else {
                    url = `${HostUrl}/api/box/${this.boxId}/takeout/control`;
                }
            } else if (this.totalAmount === 0 && (this.zero_stamp+INTERVAL_BTN_FEE_AND_CONTROL) > timestamp) {
                url = `${HostUrl}/api/box/${this.boxId}/takeout/control`;
            } else {
                store.commit('setSnackBar', { message: '[' + i18n.t('takeout.transaction-failed') + ']: ' + i18n.t('takeout.use-cabinet-scanner'), color: 'error' })
                this.initAll()
                return
            }
            this.showDialog(i18n.t('takeout.open-cell'), i18n.t('takeout.fail-open-cell'), 40000)
            var self = this
            axios.post(url, payload, getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) {
                        if(response.data && response.data.payment_url && response.data.payment_url.web) {
                            window.location.href = response.data.payment_url.web
                        } else {
                            self.takeoutPackages = response.data.packages
                        }
                        if (!self.takeoutPackages) self.emptyDialog = true
                    } else if (response.data.code === 508) {
                        if (response.data.message === '付款完成') response.data.message = i18n.t('putIn.payment-completed')
                            self.message = response.data.message
                            self.dialogMessage = response.data.message
                            self.emptyDialog = true
                            self.closeDialog()
                    } else if (response.data.code === 509) {
                        self.$refs.paymentSelector.$refs.directPay[0].payByPrime()
                        store.commit('setSnackBar', { message: i18n.t('putIn.transaction-fail'), color: 'error' })
                        self.closeDialog()
                    } else {
                        if (response.data.message === '密碼錯誤, 請使用註冊時，輸入的密碼') {
                            response.data.message = i18n.t('takeout.password-wrong')
                        }
                        if (response.data.message === '密碼錯誤, 請輸入信用卡前六碼') {
                            response.data.message = i18n.t('takeout.enter-six-digits')
                        }
                        if (response.data.message === '密碼錯誤, 請輸入設定的密碼') {
                            response.data.message = i18n.t('takeout.password-you-set')
                        }
                        self.closeDialog()
                        store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                    }
                })
                .catch(function () {
                    self.closeDialog()
                    store.commit('setSnackBar', { message: i18n.t('takeout.transaction-fail'), color: 'error' })
                })
        },
        istakepagePackagealarm(p){
            if (this.isFromLinePay) {
                if (p.type === TransTypes.LongLeave) {
                    return true
                } else return false
            } else {
                if (p.type === TransTypes.LongLeave) {
                    if (p.amount < p.relet_amount & p.amount != 0 & this.selectTrackNos[p.track_no] == TransTypes.Leave)
                        return false
                    else
                        return true
                }
                else
                    return false
            }
        },
        typeName(door_name) {
            if (door_name) {
                return JSON.stringify(door_name)
            } else return ""
        },
        reopen(index) {
            //限制不得超過50秒後按重開按鍵
            let overTime = store.getters.mqttPayload + 51000
            let currentTime = new Date().getTime()            
            if(currentTime > overTime) {
                this.showMsgDialog(i18n.t('takeout.cannot-taken-out'), i18n.t('takeout.close'))
                store.commit('setReopenTimeLimit', false)
                return
            }
            this.showDialog(i18n.t('takeout.open-cell'), i18n.t('takeout.fail-open-cell'), 40000)
            var topic = `box/${this.boxId}/trans/control`
            let p = this.takeoutPackages[index]
            let transtype = p.type
            if(this.isFromLinePay === false) {
                if (p.type == TransTypes.LongLeave & p.amount < p.relet_amount & p.amount != 0) {
                    transtype = this.selectTrackNos[p.track_no]
                }
            } 
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                action: MQTT_ACTION.TAKEOUT_ACTION,
                track_no: { [p.track_no] : {
                    door_id: p.door_id,
                    trans_type: transtype
                }}
            }, 1)
        },
        updatePackagesWithControlResult: function(resultObj) {
            if (this.takeoutPackages && this.takeoutPackages.length !== 0) {
                this.takeoutPackages.filter(p => p.track_no in resultObj).forEach(p => {
                    if (resultObj[p.track_no] < 0) p.message = i18n.t('takeout.fail-open');
                    else p.message = ''
                })
            } else {
                this.rpiMode = true
                let box = ''
                this.takeoutPackages = Object.keys(resultObj).map(item => {
                    let message = ''
                    if (resultObj[item]['status'] < 0) message = i18n.t('takeout.fail-open');
                    if (this.boxId == '' && this.boxId.length < 6) box = item.substring(0, 6);
                    return {
                        track_no: item,
                        door_id: resultObj[item]['door_id'],
                        message: message,
                        openable: true,
                        type: this.transtype
                    }
                });
                this.boxId = box
            }
            store.commit('setReopenTimeLimit', true)
        },
        leave() {
            this.initAll();
            this.$router.push(`/${i18n.locale}/washhome`);
        },
        checkUrl() {
            let comfirmUrl = location.href
            if (comfirmUrl.indexOf('?') !== -1 & comfirmUrl.indexOf('transactionId') !== -1) {
                store.commit('setIsFromLinePay', true)
                this.isFromLinePay = true
                this.showDialog(i18n.t('takeout.trading'), i18n.t('takeout.transaction-failed'), 40000)
                let objectURL = new URL(comfirmUrl)
                let params = objectURL.searchParams
                let urlParamsArray = []
                for (let pair of params.entries()) {
                    let obj = {
                        key: pair[0],
                        value: pair[1]
                    }
                    urlParamsArray.push(obj)
                }
                if (urlParamsArray && urlParamsArray[0] && urlParamsArray[0].value) {
                    let paymentType = LinePayType
                    if (urlParamsArray[0].key === 'jko_transactionId') {
                        paymentType = JKOPayType
                        this.message = i18n.t('takeout.jkopay-transaction')
                    }
                    let payload = {
                        client_id: store.getters.clientId,
                        payment_type: paymentType,
                        transactionId: urlParamsArray[0].value
                    }
                    let url = HostUrl + `/api/box/takeout/confirm_control`
                    let self = this;
                    axios.post(url, payload, getAxiosConfig())
                        .then(function(response){
                            if (response.data.code === 0) {
                                store.commit('setTargetBoxId', response.data.box_id)
                                self.boxId = response.data.box_id
                                self.takeoutPackages = response.data.packages
                                if (!self.takeoutPackages) self.emptyDialog = true
                            } else if (response.data.code === 508) {
                                if (response.data.message === '付款完成') response.data.message = i18n.t('putIn.payment-completed')
                                self.message = response.data.message
                                self.dialogMessage = response.data.message
                                self.emptyDialog = true
                                self.closeDialog()
                            } else {
                                self.closeDialog()
                                store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                            }
                        })
                        .catch(function () {
                            self.closeDialog()
                            store.commit('setSnackBar', { message: i18n.t('takeout.transaction-fail'), color: 'error' })
                        })
                }
            }
        }
    },
    activated() {
        store.commit('setSideBtnType', SideBtnType.Back)
        this.initAll()
        this.checkUrl()
    },
    deactivated() {
        this.camera = 'off'
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
};
</script>

<style scoped>
</style>
